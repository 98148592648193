import React from "react";


function ContactPage() {
  return (
    <section className="h-1/2">
      <div className="max-w-4xl mx-auto ">
        <form name="contactform" className="m-auto p-12" data-netlify="true" method="POST">

          <div className="">
            <h1 className="inline-block mb-4 text-5xl font-bold text-left">Contact Us</h1>
            <h2 className="mb-8 leading-loose">
              Please contact us with the form below.
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-min grid-flow-row-dense gap-x-20">
            <div className="">
              <label
                className="block mb-2 text-xs font-bold uppercase"
                htmlFor="first-name"
              >
                First Name
              </label>

              <input
                className="w-full mb-6 rounded-md border-gray-800"
                id="first-name"
                name="first-name"
                placeholder="Ro"
                type="text"
              />
            </div>
            <div className="">
              <label
                className="block mb-2 text-xs font-bold uppercase"
                htmlFor="last-name"
              >
                Last Name
              </label>

              <input
                className="w-full mb-6 rounded-md border-gray-800"
                id="last-name"
                name="last-name"
                placeholder="Bot"
                type="text"
              />
            </div>

            <div className="">
              <label
                className="block mb-2 text-xs font-bold uppercase"
                htmlFor="company"
              >
                Company
              </label>

              <input
                className="w-full mb-6 rounded-md border-gray-800"
                id="company"
                name="company"
                placeholder="Company"
                type="text"
              />
            </div >

            <div className="">
              <label
                className="block mb-2 text-xs font-bold uppercase"
                htmlFor="email"
              >
                Email
              </label>

              <input
                className="w-full mb-6 rounded-md border-gray-800"
                id="email"
                name="email"
                placeholder="robot@factory.com"
                type="email"
              />
            </div >

            <div className="">
              <label
                className="block mb-2 text-xs font-bold uppercase"
                htmlFor="message"
              >
                Message
              </label>

              <textarea
                className="w-full mb-6 rounded-md border-gray-800"
                id="message"
                name="message"
                placeholder="Say something..."
                rows="4"
              />
            </div>
          </div>
          <div className="">
            <button className="px-4 py-2 text-sm font-bold text-white bg-gray-700 border-b-4 border-gray-800 rounded hover:border-gray-700 hover:bg-gray-600">
              Submit
            </button>
          </div>
        </form >
      </div>
    </section >
  );
}

export default ContactPage;
